import { template as template_d671a36c48f1436b8e53872bd34f74ae } from "@ember/template-compiler";
const FKText = template_d671a36c48f1436b8e53872bd34f74ae(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
